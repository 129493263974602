import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import slider1 from "../images/slider1.jpg";
import slider2 from "../images/slider2.jpg";
import slider3 from "../images/slider3.jpg";
import slider4 from "../images/slider4.jpg";
import BgImage from "../images/BgImage.jpg";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import keta from "../images/Keta.jpg";
import keta1 from "../images/keta1.jpg";
import ImageSlider from "./ImageSlider";
import Person from "../images/person.jpeg.jpg";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [registerData, setRegisterData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });
  const [registerErrors, setRegisterErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const images = [slider1, slider2, slider3, slider4];

  const overlays = [
    {
      text: "බූරුවා",
      text1: "This is Your Time",
      buttonText: "Play Now",
      link: "https://cardfrontend.lukzbet.com/",
    },
    {
      text: "Second Slide",
      text1: "This is Your Time",
      buttonText: "Play Now",
      link: "https://cardfrontend.lukzbet.com/",
    },
    {
      text: "Third Slide",
      text1: "This is Your Time",
      buttonText: "Play Now",
      link: "https://cardfrontend.lukzbet.com/",
    },
    {
      text: "Fourth Slide",
      text1: "This is Your Time",
      buttonText: "Play Us",
      link: "https://cardfrontend.lukzbet.com/",
    },
  ];

  const handleClickOpenLogin = () => {
    handleCloseRegister();
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleClickOpenRegister = () => {
    handleCloseLogin();
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  const validateLoginForm = () => {
    let isValid = true;
    let errors = { email: "", password: "" };

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email.";
      isValid = false;
    }

    if (!formData.password || formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const [betData, setBetData] = useState([]);

  useEffect(() => {
    // Fetch data from backend
    axios
      .get("https://cardbackend.lukzbet.com/api/bet-data") // Replace with your Laravel server URL
      .then((response) => {
        setBetData(response.data); // Set the bet data in state
      })
      .catch((error) => {
        console.error("Error fetching bet data:", error);
      });
  }, []);

  const validateRegisterForm = () => {
    let isValid = true;
    let errors = { username: "", email: "", password: "", confirmPassword: "" };

    if (!registerData.username) {
      errors.username = "Username is required.";
      isValid = false;
    }

    if (!registerData.email || !/\S+@\S+\.\S+/.test(registerData.email)) {
      errors.email = "Please enter a valid email.";
      isValid = false;
    }

    if (!registerData.password || registerData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    if (registerData.password !== registerData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
      isValid = false;
    }

    setRegisterErrors(errors);
    return isValid;
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (validateLoginForm()) {
      console.log("Login successful:", formData);
      handleCloseLogin();
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRedirect = async () => {
    const token = localStorage.getItem("auth_token");
    try {
      // Make a GET request to the Laravel backend route that generates the redirect URL
      const response = await axios.get(
        "https://backend.lukzbet.com/api/redirect-to-game",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use your user's auth token
          },
        }
      );

      if (response.data.status) {
        // Redirect to the URL provided by the backend
        window.location.href = response.data.redirect_url;
      } else {
        console.error("Failed to get the redirect URL");
      }
    } catch (error) {
      console.error("Error during redirection:", error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#0C314D",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              marginLeft: "20px",
              marginTop: "20px",
              width: { md: "95%", lg: "70%", xl: "70%" },
              height: "50vh",
            }}
          >
            <ImageSlider images={images} overlays={overlays} />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
                lx: "flex",
              },
              width: { md: "0%", lg: "30%", xl: "30%" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                marginTop: "20px",
                width: "70%",
                borderRadius: "0px",
                color: "white",
                fontSize: { lg: "18x", xl: "22px" },
              }}
              variant="h5"
            >
              Last Winners
            </Typography>
            <Box
              sx={{
                marginLeft: "20px",
                width: "90%",
                borderRadius: "0px",
                color: "white",
                marginTop: "20px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {betData.slice(-4).map((bet, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "95%",
                    height: "10vh",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      marginLeft: "20px",
                      width: "15%",
                      height: "auto",
                      borderRadius: "10px",
                      borderRadius: "50%",
                    }}
                    src={Person}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        width: "70%",
                        borderRadius: "0px",
                        color: "black",
                        opacity: "0.5",
                        fontSize: { lg: "12px", xl: "15px" },
                      }}
                      variant="p"
                    >
                      {bet.user_name}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        width: "70%",
                        marginTop: "10px",
                        borderRadius: "0px",
                        color: "black",
                        opacity: "0.5",
                        fontSize: { lg: "12px", xl: "15px" },
                      }}
                      variant="p"
                    >
                      in Buruwa
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      width: "70%",
                      marginTop: "10px",
                      borderRadius: "0px",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: { lg: "12px", xl: "15px" },
                    }}
                    variant="p"
                  >
                    {bet.bet_amount}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: "20px",
            marginTop: "40px",
            display: "flex",
            flexDirection: "ros",
            justifyContent: "space-between",
            width: "97%",
          }}
        >
          <Grid
            container
            sx={{
              gap: "10px",
              marginTop: "40px",
              display: "flex",
              flexDirection: "ros",
              width: "97%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.9}
              sx={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "10%",
                height: "15vh",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px #000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                viewBox="0 0 512 500"
              >
                <linearGradient
                  id="a"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="-393.047"
                  x2="244.309"
                  y2="136.563"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffcf95" />
                  <stop offset=".427" stop-color="#ffc954" />
                  <stop offset="1" stop-color="#ffc200" />
                </linearGradient>
                <path
                  d="M274.8 23.2l67.7 137.2 151.4 22c17.2 2.5 24.1 23.7 11.6 35.8L396.1 325.1 422 475.9c2.9 17.2-15.1 30.2-30.5 22.1L256 426.8 120.6 498c-15.4 8.1-33.4-5-30.5-22.1L116 325.1 6.4 218.3C-6.1 206.1.8 185 18 182.5l151.4-22 67.7-137.2c7.8-15.7 30-15.7 37.7-.1z"
                  fill="url(#a)"
                />
                <linearGradient
                  id="b"
                  gradientUnits="userSpaceOnUse"
                  x1="368.716"
                  y1="-324.737"
                  x2="208.617"
                  y2="-324.737"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffc200" stop-opacity="0" />
                  <stop offset=".203" stop-color="#fb0" stop-opacity=".203" />
                  <stop
                    offset=".499"
                    stop-color="#ffa700"
                    stop-opacity=".499"
                  />
                  <stop offset=".852" stop-color="#f80" stop-opacity=".852" />
                  <stop offset="1" stop-color="#ff7800" />
                </linearGradient>
                <path
                  d="M342.6 160.4L274.8 23.2c-3.9-7.8-11.3-11.7-18.8-11.7v259.8l86.6-110.9z"
                  fill="url(#b)"
                />
                <linearGradient
                  id="c"
                  gradientUnits="userSpaceOnUse"
                  x1="396.053"
                  y1="-79.454"
                  x2="353.653"
                  y2="-288.254"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffc200" stop-opacity="0" />
                  <stop offset=".203" stop-color="#fb0" stop-opacity=".203" />
                  <stop
                    offset=".499"
                    stop-color="#ffa700"
                    stop-opacity=".499"
                  />
                  <stop offset=".852" stop-color="#f80" stop-opacity=".852" />
                  <stop offset="1" stop-color="#ff7800" />
                </linearGradient>
                <path
                  d="M396.1 325.1l109.6-106.8c6.2-6 7.6-14.2 5.4-21.2l-255 74.3 140 53.7z"
                  fill="url(#c)"
                />
                <linearGradient
                  id="d"
                  gradientUnits="userSpaceOnUse"
                  x1="190.474"
                  y1="30.27"
                  x2="356.534"
                  y2="-135.79"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffc200" stop-opacity="0" />
                  <stop offset=".203" stop-color="#fb0" stop-opacity=".203" />
                  <stop
                    offset=".499"
                    stop-color="#ffa700"
                    stop-opacity=".499"
                  />
                  <stop offset=".852" stop-color="#f80" stop-opacity=".852" />
                  <stop offset="1" stop-color="#ff7800" />
                </linearGradient>
                <path
                  d="M413.6 496.4L256 271.3v155.5L391.4 498c7.8 4.1 16.2 2.8 22.2-1.6z"
                  fill="url(#d)"
                />
                <linearGradient
                  id="e"
                  gradientUnits="userSpaceOnUse"
                  x1="72.052"
                  y1="-179.721"
                  x2="194.452"
                  y2="-77.321"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffc200" stop-opacity="0" />
                  <stop offset=".203" stop-color="#fb0" stop-opacity=".203" />
                  <stop
                    offset=".499"
                    stop-color="#ffa700"
                    stop-opacity=".499"
                  />
                  <stop offset=".852" stop-color="#f80" stop-opacity=".852" />
                  <stop offset="1" stop-color="#ff7800" />
                </linearGradient>
                <path
                  d="M256 271.3l-140.1 53.8-25.8 150.8c-1.5 8.5 2.3 16.1 8.3 20.5L256 271.3z"
                  fill="url(#e)"
                />
                <linearGradient
                  id="f"
                  gradientUnits="userSpaceOnUse"
                  x1="163.811"
                  y1="-348.638"
                  x2="114.211"
                  y2="-207.838"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffc200" stop-opacity="0" />
                  <stop offset=".203" stop-color="#fb0" stop-opacity=".203" />
                  <stop
                    offset=".499"
                    stop-color="#ffa700"
                    stop-opacity=".499"
                  />
                  <stop offset=".852" stop-color="#f80" stop-opacity=".852" />
                  <stop offset="1" stop-color="#ff7800" />
                </linearGradient>
                <path
                  d="M1 197l255 74.3-86.6-110.9-151.4 22c-8.7 1.3-14.8 7.4-17 14.6z"
                  fill="url(#f)"
                />
                <linearGradient
                  id="g"
                  gradientUnits="userSpaceOnUse"
                  x1="350.521"
                  y1="-333.928"
                  x2="-13.879"
                  y2="-178.728"
                  gradientTransform="translate(-4.604 487.793) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#ffc200" stop-opacity="0" />
                  <stop offset=".203" stop-color="#fb0" stop-opacity=".203" />
                  <stop
                    offset=".499"
                    stop-color="#ffa700"
                    stop-opacity=".499"
                  />
                  <stop offset=".852" stop-color="#f80" stop-opacity=".852" />
                  <stop offset="1" stop-color="#ff7800" />
                </linearGradient>
                <path
                  d="M115.9 325.1L6.4 218.3c-6.2-6-7.6-14.2-5.4-21.2l255 74.3-140.1 53.7z"
                  fill="url(#g)"
                />
              </svg>
              <Typography
                sx={{
                  marginTop: "10px",
                  borderRadius: "0px",
                  color: "black",
                  fontWeight: "bold",
                  color: "white",
                }}
                variant="h5"
              >
                All
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.9}
              sx={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "15%",
                height: "15vh",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px #000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                viewBox="0 0 512 512"
              >
                <radialGradient
                  id="a"
                  cx="243.569"
                  cy="263.824"
                  r="266.634"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
                  fill="url(#a)"
                />
                <linearGradient
                  id="b"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="295.446"
                  x2="256"
                  y2="-162.015"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 91.4 47.9 171.5 119.9 216.8h272.2C464.1 427.5 512 347.4 512 256S464.1 84.5 392.1 39.2H119.9C47.9 84.5 0 164.6 0 256z"
                  fill="url(#b)"
                />
                <linearGradient
                  id="c"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="193.051"
                  x2="256"
                  y2="655.334"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 65.2 24.4 124.7 64.5 169.9h383C487.6 380.7 512 321.2 512 256s-24.4-124.7-64.5-169.9h-383C24.4 131.3 0 190.8 0 256z"
                  fill="url(#c)"
                />
                <linearGradient
                  id="d"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="247.26"
                  x2="256"
                  y2="99.415"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 38.1 8.3 74.2 23.3 106.7h465.5C503.7 330.2 512 294.1 512 256s-8.3-74.2-23.3-106.7H23.3C8.3 181.8 0 217.9 0 256z"
                  fill="url(#d)"
                />
                <linearGradient
                  id="e"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="199.074"
                  x2="256"
                  y2="450.832"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 22.1 2.8 43.4 8 63.9h496c5.2-20.4 8-41.8 8-63.9s-2.8-43.4-8-63.9H8c-5.2 20.5-8 41.8-8 63.9z"
                  fill="url(#e)"
                />
                <radialGradient
                  id="f"
                  cx="160.75"
                  cy="324.056"
                  r="358.384"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M235.7.8C103.8 11.2 0 121.5 0 256s103.8 244.8 235.7 255.2C367.6 500.8 471.3 390.5 471.3 256S367.6 11.2 235.7.8z"
                  fill="url(#f)"
                />
                <radialGradient
                  id="g"
                  cx="235.672"
                  cy="255"
                  r="224.174"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#fffcf8" />
                  <stop
                    offset=".949"
                    stop-color="#fffcf8"
                    stop-opacity=".051"
                  />
                  <stop offset="1" stop-color="#fffcf8" stop-opacity="0" />
                </radialGradient>
                <path
                  d="M248.9 24.2c-8.8-1.2-17.7-1.2-26.5 0C108.3 39.8 20.3 137.6 20.3 256s88 216.2 202.1 231.8c8.8 1.2 17.7 1.2 26.5 0C363.1 472.2 451 374.4 451 256S363.1 39.8 248.9 24.2z"
                  fill="url(#g)"
                />
                <radialGradient
                  id="h"
                  cx="264.056"
                  cy="278.958"
                  r="291.192"
                  gradientTransform="matrix(.9201 0 0 -1.0039 9.2 512.102)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M405.8 256c0-103.2-76.6-186.8-171.2-186.8-94.5 0-171.2 83.6-171.2 186.8S140 442.8 234.6 442.8 405.8 359.2 405.8 256z"
                  fill="url(#h)"
                />
                <radialGradient
                  id="i"
                  cx="183.684"
                  cy="319.242"
                  r="273.032"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M281.6 76.4c-21.3-6.6-44.1-6.6-65.4 0C144.6 98.6 92.1 170.6 92.1 256c0 87 54.6 160.2 128.4 180.9 18.6 5.2 38.3 5.2 56.9 0C351.2 416.2 405.8 343 405.8 256c0-85.4-52.5-157.4-124.2-179.6z"
                  fill="url(#i)"
                />
                <linearGradient
                  id="j"
                  gradientUnits="userSpaceOnUse"
                  x1="349.663"
                  y1="201.069"
                  x2="139.351"
                  y2="249.623"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#aa6d00" stop-opacity="0" />
                  <stop
                    offset=".635"
                    stop-color="#ec8c00"
                    stop-opacity=".635"
                  />
                  <stop offset="1" stop-color="#e58200" />
                </linearGradient>
                <path
                  d="M409 276L256.9 147.7c-.2-2.1-2.3-3.8-4.7-3.8h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3L212 147.7c-.2-2.1-2.3-3.8-4.7-3.8H190c-2.6 0-4.7 1.9-4.7 4.2v13.3h-14.9c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.2 1.9 4 4.3 4.2l15.4 14.8V311h-14.9c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.2 1.9 4 4.3 4.2l107.6 103.7c78-11.5 113.8-91.8 131.3-160.9z"
                  fill="url(#j)"
                />
                <path
                  d="M295.6 253.7c-4.5-5.9-11.3-10.4-20.2-13.5 14.9-5.9 22.3-18.6 22.3-38.1 0-15.2-4.8-25.7-14.4-31.7-7.2-4.4-16.1-7.2-26.9-8.3v-13.9c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3h-18.1v-13.3c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3H170c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.3 2.1 4.2 4.7 4.2h14.9V311H170c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.3 2.1 4.2 4.7 4.2h14.9v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2v-13.3h18.1v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2V333c13.1-.9 23.7-4.7 31.9-11.5 9.4-7.8 14.2-20.4 14.2-37.6v-4.5c-.1-11.2-2.4-19.8-6.9-25.7zm-83.4-70.1h30.7c18 0 27 7.5 27 22.6 0 16.1-8.8 24.2-26.5 24.2h-31.2v-46.8zm62.4 98.4c0 19.4-9.9 29.1-29.6 29.1h-32.8V250H245c9.8 0 17.2 2.2 22.2 6.6 5 4.4 7.5 11.6 7.5 21.6v3.8z"
                  fill="#ffce33"
                />
              </svg>
              <Typography
                sx={{
                  marginTop: "10px",
                  borderRadius: "0px",
                  color: "black",
                  fontWeight: "bold",
                  color: "white",
                }}
                variant="h5"
              >
                Hot
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.9}
              sx={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "15%",
                height: "15vh",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px #000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                viewBox="0 0 512 512"
              >
                <linearGradient
                  id="a"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="128.484"
                  x2="244.309"
                  y2="-480.014"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#red" />
                </linearGradient>
                <circle cx="256" cy="256" r="256" fill="white" />
                <linearGradient
                  id="b"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="-551.896"
                  x2="244.309"
                  y2="36.884"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <circle cx="256" cy="256" r="247.7" fill="white" />
                <linearGradient
                  id="c"
                  gradientUnits="userSpaceOnUse"
                  x1="130.558"
                  y1="-331.048"
                  x2="347.008"
                  y2="-114.598"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#1acee5" />
                  <stop offset="1" stop-color="#0bbff9" />
                </linearGradient>
                <circle cx="256" cy="256" r="223.4" fill="#0F9DFB" />
                <radialGradient
                  id="d"
                  cx="258.416"
                  cy="-198.976"
                  r="226.11"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#00baff" stop-opacity="0" />
                  <stop offset=".685" stop-color="#00baff" stop-opacity=".69" />
                  <stop offset=".918" stop-color="#00baff" stop-opacity=".92" />
                  <stop offset="1" stop-color="#00baff" />
                </radialGradient>
                <circle cx="256" cy="256" r="223.4" fill="#0F9DFB" />
                <linearGradient
                  id="e"
                  gradientUnits="userSpaceOnUse"
                  x1="283.958"
                  y1="-189.473"
                  x2="180.618"
                  y2="-292.803"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#0092ff" stop-opacity="0" />
                  <stop offset="1" stop-color="#0092ff" />
                </linearGradient>
                <path
                  d="M486.9 289.7l-74.7-74.1-21.2 5.2-108.3-107.3c-1.1-2.3-2.9-4.2-5.3-5.3-1.7-3.7-5.5-6.3-9.9-6.3h-3.2c-6 0-10.9 4.9-10.9 10.9v126.7c-4.9 3.8-8.2 9.7-8.2 16.4 0 2.1.3 4.1.9 6l-70.4 70.4c-4.2 4.2-4.2 11.1 0 15.4L304.2 476c94.3-16.2 168.3-91.5 182.7-186.3z"
                  fill="#0F9DFB"
                />
                <linearGradient
                  id="f"
                  gradientUnits="userSpaceOnUse"
                  x1="371.329"
                  y1="-71.33"
                  x2="224.769"
                  y2="-266.45"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#0092ff" stop-opacity="0" />
                  <stop offset="1" stop-color="#0092ff" />
                </linearGradient>
                <path
                  d="M476.9 289.7c-14.4 94.8-88.3 170.1-182.5 186.4L168.3 350.2c4.2 4.1 11 4 15.2-.2l73.4-73.4c10.9-.5 19.6-9.5 19.7-20.5l127.8-31.5c1.7-.4 3-1.8 3.4-3.4l69.1 68.5z"
                  fill="#0F9DFB"
                />
                <circle
                  cx="256"
                  cy="252.8"
                  r="224.3"
                  opacity=".17"
                  fill="#3e3ef4"
                />
                <linearGradient
                  id="g"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="-415.674"
                  x2="244.309"
                  y2="-204.986"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <path
                  d="M257.6 256h-3.2c-6 0-10.9-4.9-10.9-10.9V112.9c0-6 4.9-10.9 10.9-10.9h3.2c6 0 10.9 4.9 10.9 10.9v132.3c0 5.9-4.9 10.8-10.9 10.8z"
                  fill="url(#g)"
                />
                <linearGradient
                  id="h"
                  gradientUnits="userSpaceOnUse"
                  x1="224.028"
                  y1="-193.364"
                  x2="153.738"
                  y2="-123.074"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <path
                  d="M257.6 256l2.3 2.3c4.2 4.2 4.2 11.1 0 15.4L183.5 350c-4.2 4.2-11.1 4.2-15.4 0l-2.3-2.3c-4.2-4.2-4.2-11.1 0-15.4l76.4-76.4c4.3-4.1 11.2-4.1 15.4.1z"
                  fill="url(#h)"
                />
                <linearGradient
                  id="i"
                  gradientUnits="userSpaceOnUse"
                  x1="240.916"
                  y1="-216.667"
                  x2="412.866"
                  y2="-257.577"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#d63305" />
                  <stop offset=".366" stop-color="#cf3004" />
                  <stop offset=".899" stop-color="#bc2602" />
                  <stop offset="1" stop-color="#b72401" />
                </linearGradient>
                <path
                  d="M407.8 219.1c.6 2.5-.9 5-3.4 5.6l-146.1 36c-2.5.6-5-.9-5.6-3.4-.6-2.5.9-5 3.4-5.6l146.1-36c2.5-.6 5 .9 5.6 3.4z"
                  fill="url(#i)"
                />
                <linearGradient
                  id="j"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="-176.976"
                  x2="244.309"
                  y2="-238.056"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#97aaaa" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <circle cx="256" cy="256" r="20.6" fill="url(#j)" />
                <linearGradient
                  id="k"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="-422.901"
                  x2="244.309"
                  y2="-371.016"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <path
                  d="M256.8 88.8h-1.6c-6.5 0-11.7-5.2-11.7-11.7V62.6c0-6.5 5.2-11.7 11.7-11.7h1.6c6.5 0 11.7 5.2 11.7 11.7v14.5c0 6.5-5.2 11.7-11.7 11.7z"
                  fill="url(#k)"
                />
                <linearGradient
                  id="l"
                  gradientUnits="userSpaceOnUse"
                  x1="244.309"
                  y1="-73.846"
                  x2="244.309"
                  y2="-21.966"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <path
                  d="M256.8 461.1h-1.6c-6.5 0-11.7-5.2-11.7-11.7v-14.5c0-6.5 5.2-11.7 11.7-11.7h1.6c6.5 0 11.7 5.2 11.7 11.7v14.5c0 6.5-5.2 11.7-11.7 11.7z"
                  fill="url(#l)"
                />
                <linearGradient
                  id="m"
                  gradientUnits="userSpaceOnUse"
                  x1="448.327"
                  y1="128.62"
                  x2="396.437"
                  y2="-479.878"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <path
                  d="M423.2 256.8v-1.6c0-6.5 5.2-11.7 11.7-11.7h14.5c6.5 0 11.7 5.2 11.7 11.7v1.6c0 6.5-5.2 11.7-11.7 11.7h-14.5c-6.5 0-11.7-5.2-11.7-11.7z"
                  fill="url(#m)"
                />
                <linearGradient
                  id="n"
                  gradientUnits="userSpaceOnUse"
                  x1="99.279"
                  y1="128.619"
                  x2="47.401"
                  y2="-479.879"
                  gradientTransform="translate(-4.604 487.79) scale(1.0667)"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8efee" />
                </linearGradient>
                <path
                  d="M50.9 256.8v-1.6c0-6.5 5.2-11.7 11.7-11.7h14.5c6.5 0 11.7 5.2 11.7 11.7v1.6c0 6.5-5.2 11.7-11.7 11.7H62.6c-6.5 0-11.7-5.2-11.7-11.7z"
                  fill="url(#n)"
                />
              </svg>
              <Typography
                sx={{
                  marginTop: "10px",
                  borderRadius: "0px",
                  color: "black",
                  fontWeight: "bold",
                  color: "white",
                }}
                variant="h5"
              >
                New
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.9}
              sx={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "15%",
                height: "15vh",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px #000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                viewBox="0 0 512 512"
              >
                <radialGradient
                  id="a"
                  cx="243.569"
                  cy="263.824"
                  r="266.634"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
                  fill="url(#a)"
                />
                <linearGradient
                  id="b"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="295.446"
                  x2="256"
                  y2="-162.015"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 91.4 47.9 171.5 119.9 216.8h272.2C464.1 427.5 512 347.4 512 256S464.1 84.5 392.1 39.2H119.9C47.9 84.5 0 164.6 0 256z"
                  fill="url(#b)"
                />
                <linearGradient
                  id="c"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="193.051"
                  x2="256"
                  y2="655.334"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 65.2 24.4 124.7 64.5 169.9h383C487.6 380.7 512 321.2 512 256s-24.4-124.7-64.5-169.9h-383C24.4 131.3 0 190.8 0 256z"
                  fill="url(#c)"
                />
                <linearGradient
                  id="d"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="247.26"
                  x2="256"
                  y2="99.415"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 38.1 8.3 74.2 23.3 106.7h465.5C503.7 330.2 512 294.1 512 256s-8.3-74.2-23.3-106.7H23.3C8.3 181.8 0 217.9 0 256z"
                  fill="url(#d)"
                />
                <linearGradient
                  id="e"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="199.074"
                  x2="256"
                  y2="450.832"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 22.1 2.8 43.4 8 63.9h496c5.2-20.4 8-41.8 8-63.9s-2.8-43.4-8-63.9H8c-5.2 20.5-8 41.8-8 63.9z"
                  fill="url(#e)"
                />
                <radialGradient
                  id="f"
                  cx="160.75"
                  cy="324.056"
                  r="358.384"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M235.7.8C103.8 11.2 0 121.5 0 256s103.8 244.8 235.7 255.2C367.6 500.8 471.3 390.5 471.3 256S367.6 11.2 235.7.8z"
                  fill="url(#f)"
                />
                <radialGradient
                  id="g"
                  cx="235.672"
                  cy="255"
                  r="224.174"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#fffcf8" />
                  <stop
                    offset=".949"
                    stop-color="#fffcf8"
                    stop-opacity=".051"
                  />
                  <stop offset="1" stop-color="#fffcf8" stop-opacity="0" />
                </radialGradient>
                <path
                  d="M248.9 24.2c-8.8-1.2-17.7-1.2-26.5 0C108.3 39.8 20.3 137.6 20.3 256s88 216.2 202.1 231.8c8.8 1.2 17.7 1.2 26.5 0C363.1 472.2 451 374.4 451 256S363.1 39.8 248.9 24.2z"
                  fill="url(#g)"
                />
                <radialGradient
                  id="h"
                  cx="264.056"
                  cy="278.958"
                  r="291.192"
                  gradientTransform="matrix(.9201 0 0 -1.0039 9.2 512.102)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M405.8 256c0-103.2-76.6-186.8-171.2-186.8-94.5 0-171.2 83.6-171.2 186.8S140 442.8 234.6 442.8 405.8 359.2 405.8 256z"
                  fill="url(#h)"
                />
                <radialGradient
                  id="i"
                  cx="183.684"
                  cy="319.242"
                  r="273.032"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M281.6 76.4c-21.3-6.6-44.1-6.6-65.4 0C144.6 98.6 92.1 170.6 92.1 256c0 87 54.6 160.2 128.4 180.9 18.6 5.2 38.3 5.2 56.9 0C351.2 416.2 405.8 343 405.8 256c0-85.4-52.5-157.4-124.2-179.6z"
                  fill="url(#i)"
                />
                <linearGradient
                  id="j"
                  gradientUnits="userSpaceOnUse"
                  x1="349.663"
                  y1="201.069"
                  x2="139.351"
                  y2="249.623"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#aa6d00" stop-opacity="0" />
                  <stop
                    offset=".635"
                    stop-color="#ec8c00"
                    stop-opacity=".635"
                  />
                  <stop offset="1" stop-color="#e58200" />
                </linearGradient>
                <path
                  d="M409 276L256.9 147.7c-.2-2.1-2.3-3.8-4.7-3.8h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3L212 147.7c-.2-2.1-2.3-3.8-4.7-3.8H190c-2.6 0-4.7 1.9-4.7 4.2v13.3h-14.9c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.2 1.9 4 4.3 4.2l15.4 14.8V311h-14.9c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.2 1.9 4 4.3 4.2l107.6 103.7c78-11.5 113.8-91.8 131.3-160.9z"
                  fill="url(#j)"
                />
                <path
                  d="M295.6 253.7c-4.5-5.9-11.3-10.4-20.2-13.5 14.9-5.9 22.3-18.6 22.3-38.1 0-15.2-4.8-25.7-14.4-31.7-7.2-4.4-16.1-7.2-26.9-8.3v-13.9c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3h-18.1v-13.3c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3H170c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.3 2.1 4.2 4.7 4.2h14.9V311H170c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.3 2.1 4.2 4.7 4.2h14.9v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2v-13.3h18.1v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2V333c13.1-.9 23.7-4.7 31.9-11.5 9.4-7.8 14.2-20.4 14.2-37.6v-4.5c-.1-11.2-2.4-19.8-6.9-25.7zm-83.4-70.1h30.7c18 0 27 7.5 27 22.6 0 16.1-8.8 24.2-26.5 24.2h-31.2v-46.8zm62.4 98.4c0 19.4-9.9 29.1-29.6 29.1h-32.8V250H245c9.8 0 17.2 2.2 22.2 6.6 5 4.4 7.5 11.6 7.5 21.6v3.8z"
                  fill="#ffce33"
                />
              </svg>
              <Typography
                sx={{
                  marginTop: "10px",
                  borderRadius: "0px",
                  color: "black",
                  fontWeight: "bold",
                  color: "white",
                }}
                variant="h5"
              >
                Slots
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.9}
              sx={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "15%",
                height: "15vh",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px #000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                viewBox="0 0 512 512"
              >
                <radialGradient
                  id="a"
                  cx="243.569"
                  cy="263.824"
                  r="266.634"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
                  fill="url(#a)"
                />
                <linearGradient
                  id="b"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="295.446"
                  x2="256"
                  y2="-162.015"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 91.4 47.9 171.5 119.9 216.8h272.2C464.1 427.5 512 347.4 512 256S464.1 84.5 392.1 39.2H119.9C47.9 84.5 0 164.6 0 256z"
                  fill="url(#b)"
                />
                <linearGradient
                  id="c"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="193.051"
                  x2="256"
                  y2="655.334"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 65.2 24.4 124.7 64.5 169.9h383C487.6 380.7 512 321.2 512 256s-24.4-124.7-64.5-169.9h-383C24.4 131.3 0 190.8 0 256z"
                  fill="url(#c)"
                />
                <linearGradient
                  id="d"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="247.26"
                  x2="256"
                  y2="99.415"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 38.1 8.3 74.2 23.3 106.7h465.5C503.7 330.2 512 294.1 512 256s-8.3-74.2-23.3-106.7H23.3C8.3 181.8 0 217.9 0 256z"
                  fill="url(#d)"
                />
                <linearGradient
                  id="e"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="199.074"
                  x2="256"
                  y2="450.832"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 22.1 2.8 43.4 8 63.9h496c5.2-20.4 8-41.8 8-63.9s-2.8-43.4-8-63.9H8c-5.2 20.5-8 41.8-8 63.9z"
                  fill="url(#e)"
                />
                <radialGradient
                  id="f"
                  cx="160.75"
                  cy="324.056"
                  r="358.384"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M235.7.8C103.8 11.2 0 121.5 0 256s103.8 244.8 235.7 255.2C367.6 500.8 471.3 390.5 471.3 256S367.6 11.2 235.7.8z"
                  fill="url(#f)"
                />
                <radialGradient
                  id="g"
                  cx="235.672"
                  cy="255"
                  r="224.174"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#fffcf8" />
                  <stop
                    offset=".949"
                    stop-color="#fffcf8"
                    stop-opacity=".051"
                  />
                  <stop offset="1" stop-color="#fffcf8" stop-opacity="0" />
                </radialGradient>
                <path
                  d="M248.9 24.2c-8.8-1.2-17.7-1.2-26.5 0C108.3 39.8 20.3 137.6 20.3 256s88 216.2 202.1 231.8c8.8 1.2 17.7 1.2 26.5 0C363.1 472.2 451 374.4 451 256S363.1 39.8 248.9 24.2z"
                  fill="url(#g)"
                />
                <radialGradient
                  id="h"
                  cx="264.056"
                  cy="278.958"
                  r="291.192"
                  gradientTransform="matrix(.9201 0 0 -1.0039 9.2 512.102)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M405.8 256c0-103.2-76.6-186.8-171.2-186.8-94.5 0-171.2 83.6-171.2 186.8S140 442.8 234.6 442.8 405.8 359.2 405.8 256z"
                  fill="url(#h)"
                />
                <radialGradient
                  id="i"
                  cx="183.684"
                  cy="319.242"
                  r="273.032"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M281.6 76.4c-21.3-6.6-44.1-6.6-65.4 0C144.6 98.6 92.1 170.6 92.1 256c0 87 54.6 160.2 128.4 180.9 18.6 5.2 38.3 5.2 56.9 0C351.2 416.2 405.8 343 405.8 256c0-85.4-52.5-157.4-124.2-179.6z"
                  fill="url(#i)"
                />
                <linearGradient
                  id="j"
                  gradientUnits="userSpaceOnUse"
                  x1="349.663"
                  y1="201.069"
                  x2="139.351"
                  y2="249.623"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#aa6d00" stop-opacity="0" />
                  <stop
                    offset=".635"
                    stop-color="#ec8c00"
                    stop-opacity=".635"
                  />
                  <stop offset="1" stop-color="#e58200" />
                </linearGradient>
                <path
                  d="M409 276L256.9 147.7c-.2-2.1-2.3-3.8-4.7-3.8h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3L212 147.7c-.2-2.1-2.3-3.8-4.7-3.8H190c-2.6 0-4.7 1.9-4.7 4.2v13.3h-14.9c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.2 1.9 4 4.3 4.2l15.4 14.8V311h-14.9c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.2 1.9 4 4.3 4.2l107.6 103.7c78-11.5 113.8-91.8 131.3-160.9z"
                  fill="url(#j)"
                />
                <path
                  d="M295.6 253.7c-4.5-5.9-11.3-10.4-20.2-13.5 14.9-5.9 22.3-18.6 22.3-38.1 0-15.2-4.8-25.7-14.4-31.7-7.2-4.4-16.1-7.2-26.9-8.3v-13.9c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3h-18.1v-13.3c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3H170c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.3 2.1 4.2 4.7 4.2h14.9V311H170c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.3 2.1 4.2 4.7 4.2h14.9v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2v-13.3h18.1v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2V333c13.1-.9 23.7-4.7 31.9-11.5 9.4-7.8 14.2-20.4 14.2-37.6v-4.5c-.1-11.2-2.4-19.8-6.9-25.7zm-83.4-70.1h30.7c18 0 27 7.5 27 22.6 0 16.1-8.8 24.2-26.5 24.2h-31.2v-46.8zm62.4 98.4c0 19.4-9.9 29.1-29.6 29.1h-32.8V250H245c9.8 0 17.2 2.2 22.2 6.6 5 4.4 7.5 11.6 7.5 21.6v3.8z"
                  fill="#ffce33"
                />
              </svg>
              <Typography
                sx={{
                  marginTop: "10px",
                  borderRadius: "0px",
                  color: "black",
                  fontWeight: "bold",
                  color: "white",
                }}
                variant="h5"
              >
                Jackpot
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.9}
              sx={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "15%",
                height: "15vh",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px #000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                viewBox="0 0 512 512"
              >
                <radialGradient
                  id="a"
                  cx="243.569"
                  cy="263.824"
                  r="266.634"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
                  fill="url(#a)"
                />
                <linearGradient
                  id="b"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="295.446"
                  x2="256"
                  y2="-162.015"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 91.4 47.9 171.5 119.9 216.8h272.2C464.1 427.5 512 347.4 512 256S464.1 84.5 392.1 39.2H119.9C47.9 84.5 0 164.6 0 256z"
                  fill="url(#b)"
                />
                <linearGradient
                  id="c"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="193.051"
                  x2="256"
                  y2="655.334"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 65.2 24.4 124.7 64.5 169.9h383C487.6 380.7 512 321.2 512 256s-24.4-124.7-64.5-169.9h-383C24.4 131.3 0 190.8 0 256z"
                  fill="url(#c)"
                />
                <linearGradient
                  id="d"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="247.26"
                  x2="256"
                  y2="99.415"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 38.1 8.3 74.2 23.3 106.7h465.5C503.7 330.2 512 294.1 512 256s-8.3-74.2-23.3-106.7H23.3C8.3 181.8 0 217.9 0 256z"
                  fill="url(#d)"
                />
                <linearGradient
                  id="e"
                  gradientUnits="userSpaceOnUse"
                  x1="256"
                  y1="199.074"
                  x2="256"
                  y2="450.832"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#e58200" stop-opacity="0" />
                  <stop offset=".04" stop-color="#e27f04" stop-opacity=".039" />
                  <stop
                    offset=".467"
                    stop-color="#c15d2f"
                    stop-opacity=".467"
                  />
                  <stop offset=".802" stop-color="#ad4949" stop-opacity=".8" />
                  <stop offset="1" stop-color="#a64153" />
                </linearGradient>
                <path
                  d="M0 256c0 22.1 2.8 43.4 8 63.9h496c5.2-20.4 8-41.8 8-63.9s-2.8-43.4-8-63.9H8c-5.2 20.5-8 41.8-8 63.9z"
                  fill="url(#e)"
                />
                <radialGradient
                  id="f"
                  cx="160.75"
                  cy="324.056"
                  r="358.384"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M235.7.8C103.8 11.2 0 121.5 0 256s103.8 244.8 235.7 255.2C367.6 500.8 471.3 390.5 471.3 256S367.6 11.2 235.7.8z"
                  fill="url(#f)"
                />
                <radialGradient
                  id="g"
                  cx="235.672"
                  cy="255"
                  r="224.174"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#fffcf8" />
                  <stop
                    offset=".949"
                    stop-color="#fffcf8"
                    stop-opacity=".051"
                  />
                  <stop offset="1" stop-color="#fffcf8" stop-opacity="0" />
                </radialGradient>
                <path
                  d="M248.9 24.2c-8.8-1.2-17.7-1.2-26.5 0C108.3 39.8 20.3 137.6 20.3 256s88 216.2 202.1 231.8c8.8 1.2 17.7 1.2 26.5 0C363.1 472.2 451 374.4 451 256S363.1 39.8 248.9 24.2z"
                  fill="url(#g)"
                />
                <radialGradient
                  id="h"
                  cx="264.056"
                  cy="278.958"
                  r="291.192"
                  gradientTransform="matrix(.9201 0 0 -1.0039 9.2 512.102)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa700" />
                  <stop offset=".323" stop-color="#fca300" />
                  <stop offset=".665" stop-color="#f39600" />
                  <stop offset="1" stop-color="#e58200" />
                </radialGradient>
                <path
                  d="M405.8 256c0-103.2-76.6-186.8-171.2-186.8-94.5 0-171.2 83.6-171.2 186.8S140 442.8 234.6 442.8 405.8 359.2 405.8 256z"
                  fill="url(#h)"
                />
                <radialGradient
                  id="i"
                  cx="183.684"
                  cy="319.242"
                  r="273.032"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffc738" />
                  <stop offset=".284" stop-color="#ffc534" />
                  <stop offset=".539" stop-color="#ffbf29" />
                  <stop offset=".783" stop-color="#ffb417" />
                  <stop offset="1" stop-color="#ffa700" />
                </radialGradient>
                <path
                  d="M281.6 76.4c-21.3-6.6-44.1-6.6-65.4 0C144.6 98.6 92.1 170.6 92.1 256c0 87 54.6 160.2 128.4 180.9 18.6 5.2 38.3 5.2 56.9 0C351.2 416.2 405.8 343 405.8 256c0-85.4-52.5-157.4-124.2-179.6z"
                  fill="url(#i)"
                />
                <linearGradient
                  id="j"
                  gradientUnits="userSpaceOnUse"
                  x1="349.663"
                  y1="201.069"
                  x2="139.351"
                  y2="249.623"
                  gradientTransform="matrix(1 0 0 -1 0 511)"
                >
                  <stop offset="0" stop-color="#aa6d00" stop-opacity="0" />
                  <stop
                    offset=".635"
                    stop-color="#ec8c00"
                    stop-opacity=".635"
                  />
                  <stop offset="1" stop-color="#e58200" />
                </linearGradient>
                <path
                  d="M409 276L256.9 147.7c-.2-2.1-2.3-3.8-4.7-3.8h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3L212 147.7c-.2-2.1-2.3-3.8-4.7-3.8H190c-2.6 0-4.7 1.9-4.7 4.2v13.3h-14.9c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.2 1.9 4 4.3 4.2l15.4 14.8V311h-14.9c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.2 1.9 4 4.3 4.2l107.6 103.7c78-11.5 113.8-91.8 131.3-160.9z"
                  fill="url(#j)"
                />
                <path
                  d="M295.6 253.7c-4.5-5.9-11.3-10.4-20.2-13.5 14.9-5.9 22.3-18.6 22.3-38.1 0-15.2-4.8-25.7-14.4-31.7-7.2-4.4-16.1-7.2-26.9-8.3v-13.9c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3h-18.1v-13.3c0-2.3-2.1-4.2-4.7-4.2h-17.3c-2.6 0-4.7 1.9-4.7 4.2v13.3H170c-2.6 0-4.7 1.9-4.7 4.2v13.7c0 2.3 2.1 4.2 4.7 4.2h14.9V311H170c-2.6 0-4.7 1.9-4.7 4.2V329c0 2.3 2.1 4.2 4.7 4.2h14.9v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2v-13.3h18.1v13.3c0 2.3 2.1 4.2 4.7 4.2h17.3c2.6 0 4.7-1.9 4.7-4.2V333c13.1-.9 23.7-4.7 31.9-11.5 9.4-7.8 14.2-20.4 14.2-37.6v-4.5c-.1-11.2-2.4-19.8-6.9-25.7zm-83.4-70.1h30.7c18 0 27 7.5 27 22.6 0 16.1-8.8 24.2-26.5 24.2h-31.2v-46.8zm62.4 98.4c0 19.4-9.9 29.1-29.6 29.1h-32.8V250H245c9.8 0 17.2 2.2 22.2 6.6 5 4.4 7.5 11.6 7.5 21.6v3.8z"
                  fill="#ffce33"
                />
              </svg>
              <Typography
                sx={{
                  marginTop: "10px",
                  borderRadius: "0px",
                  color: "black",
                  fontWeight: "bold",
                  color: "white",
                }}
                variant="h5"
              >
                Roulette
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          display={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "40px",
                marginLeft: "20px",
                color: "black",
                fontWeight: "bold",
                color: "white",
                textAlign: "center",
                width: "100%",
              }}
              variant="h3"
            >
              Our Awesome Games
            </Typography>
            <Typography
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
                color: "black",
                color: "white",
                fontSize: "15px",
                textAlign: "center",
                width: "50%",
              }}
              variant="p"
            >
              Welcome to the game where every decision counts and fortunes can
              change in an instant! Get ready to test your luck, strategy, and
              instincts as you dive into a world of thrilling bets and exciting
              challenges
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "40px",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => {
              const token = localStorage.getItem("authToken"); // Check for token in localStorage
              if (token) {
                handleRedirect();
              } else {
                toast.error("Please login to play");
              }
            }}
            sx={{
              marginTop: "20px",
              cursor: "pointer",
              marginLeft: "20px",
              overflow: "hidden",
              borderRadius: "10px",
              color: "black",
              fontWeight: "bold",
              width: "90%",
              height: "500px",
              backgroundColor: "white",
              textAlign: "center",
              borderRadius: "5px",
            }}
          >
            <Avatar
              sx={{
                width: "100%",
                height: "70%",
                "&:hover": { transform: "scale(1.1)" },
                transition: "transform 0.3s ease-in-out",
                borderRadius: "5px 5px 0px 0px",
              }}
              src={keta}
            />
            <Typography
              sx={{ marginTop: "30px", color: "black", fontWeight: "bold" }}
              variant="h3"
            >
              බූරුවා
            </Typography>
            <Typography
              sx={{ marginTop: "10px", color: "black", fontWeight: "bold" }}
              variant="h5"
            >
              Play Game
            </Typography>
          </Box>
        </Box>
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              marginLeft: "10px",
              marginTop: "60px",
              gap: "10px",
              justifyContent: "space-between",
              width: "95%",
            }}
          >
            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={2.5}>
              <Box
                sx={{
                  marginTop: "20px",
                  position: "relative",
                  borderRadius: "10px",
                  color: "black",
                  fontWeight: "bold",
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#000416",
                  textAlign: "center",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <Avatar
                  sx={{
                    width: "20%",
                    height: "40%",
                    position: "absolute",
                    top: "-30px",
                    left: "40%",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                  src={slider1}
                />
                <Typography
                  sx={{
                    marginTop: "50px",
                    color: "white",
                    fontWeight: "bold",
                    opacity: "0.8",
                    fontSize: "18px",
                  }}
                  variant="h6"
                >
                  The best Methods
                </Typography>
                <Typography
                  sx={{
                    marginTop: "20px",
                    fontWeight: "100",
                    color: "white",
                    opacity: "0.8",
                    fontSize: "15px",
                  }}
                >
                  Use your preferred payment methods and easily switch between
                  these methods any time you like.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={2.5}>
              <Box
                sx={{
                  marginTop: "20px",
                  position: "relative",
                  borderRadius: "10px",
                  color: "black",
                  fontWeight: "bold",
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#000416",
                  textAlign: "center",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <Avatar
                  sx={{
                    width: "20%",
                    height: "40%",
                    position: "absolute",
                    top: "-30px",
                    left: "40%",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                  src={slider1}
                />
                <Typography
                  sx={{
                    marginTop: "50px",
                    color: "white",
                    fontWeight: "bold",
                    opacity: "0.8",
                    fontSize: "18px",
                  }}
                  variant="h6"
                >
                  Transparent transaction history
                </Typography>
                <Typography
                  sx={{
                    marginTop: "20px",
                    fontWeight: "100",
                    color: "white",
                    opacity: "0.8",
                    fontSize: "15px",
                  }}
                >
                  Keep track of your full incoming and outgoing payments
                  history.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={2.5}>
              <Box
                sx={{
                  marginTop: "20px",
                  position: "relative",
                  borderRadius: "10px",
                  color: "black",
                  fontWeight: "bold",
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#000416",
                  textAlign: "center",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <Avatar
                  sx={{
                    width: "20%",
                    height: "40%",
                    position: "absolute",
                    top: "-30px",
                    left: "40%",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                  src={slider1}
                />
                <Typography
                  sx={{
                    marginTop: "50px",
                    color: "white",
                    fontWeight: "bold",
                    opacity: "0.8",
                    fontSize: "18px",
                  }}
                  variant="h6"
                >
                  Lightning-fast deposits and withdrawals
                </Typography>
                <Typography
                  sx={{
                    marginTop: "20px",
                    fontWeight: "100",
                    color: "white",
                    opacity: "0.8",
                    fontSize: "15px",
                  }}
                >
                  Your deposits will reach your gaming account immediately and
                  withdrawals are processed faster than ever - up to 2 hours!
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={2.5}>
              <Box
                sx={{
                  marginTop: "20px",
                  position: "relative",
                  borderRadius: "10px",
                  color: "black",
                  fontWeight: "bold",
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#000416",
                  textAlign: "center",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <Avatar
                  sx={{
                    width: "20%",
                    height: "40%",
                    position: "absolute",
                    top: "-30px",
                    left: "40%",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                  src={slider1}
                />
                <Typography
                  sx={{
                    marginTop: "50px",
                    color: "white",
                    fontWeight: "bold",
                    opacity: "0.8",
                    fontSize: "18px",
                  }}
                  variant="h6"
                >
                  Complete privacy and security
                </Typography>
                <Typography
                  sx={{
                    marginTop: "20px",
                    fontWeight: "100",
                    color: "white",
                    opacity: "0.8",
                    fontSize: "15px",
                  }}
                >
                  Our website runs on 128-bit SSL encryption and uses the most
                  innovative PGP protocol to ensure safe and totally secure
                  sensitive data transfer.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: "column", marginTop: "60px" }}
        >
          <Typography
            sx={{
              marginLeft: "20px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            01. What types of games are available at LukzBet Casino?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            LukzBet Casino offers a comprehensive range of games, including slot
            machines, table games (such as Blackjack, Roulette, Baccarat, and
            Poker), and live casino games with real dealers.
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            02. Is LukzBet Casino secure and licensed?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            Yes, LukzBet Casino is committed to player security and operates
            under a license from [Regulatory Authority]. We use advanced
            encryption technology to ensure the safety of your personal and
            financial information.
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            03. How can I claim bonuses and promotions at LukzBet Casino?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            New players can claim our welcome bonus upon registration. Existing
            players can take advantage of various promotions, free spins, and
            rewards through our loyalty program. Details on how to claim these
            offers are available on our Promotions page.
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            04. What payment methods are accepted at LukzBet Casino?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            LukzBet Casino supports a variety of payment methods, including
            credit/debit cards, e-wallets, and bank transfers. Please visit our
            Banking page for a full list of available options and processing
            times.app.howto_play_casino2
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            05. How can I contact customer support?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            Our customer support team is available 24/7 via live chat, email, or
            phone. You can reach out to us for assistance with any issues or
            inquiries you may have
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            06. Can I play games for free at LukzBet Casino?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            Yes, many of our games are available in demo mode, allowing you to
            play for free before committing real money. This is a great way to
            familiarize yourself with the games and their features.
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            07. What should I do if I encounter a problem with my account?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            If you experience any issues with your account, please contact our
            customer support team immediately. We are here to help resolve any
            problems and ensure your gaming experience is smooth and enjoyable.
          </Typography>

          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "40px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "30px",
            }}
            variant="p"
          >
            08. How do I responsibly manage my gaming activity?
          </Typography>
          <Typography
            sx={{
              marginLeft: "20px",
              marginTop: "10px",
              borderRadius: "0px",
              color: "black",
              color: "black",
              color: "white",
              opacity: "0.8",
              fontSize: "18px",
            }}
            variant="p"
          >
            LukzBet Casino is dedicated to promoting responsible gaming. We
            provide tools and resources to help you set limits on your deposits,
            losses, and playtime. For more information on responsible gaming,
            please visit our Responsible Gaming page.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
