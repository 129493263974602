import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios"; // For making HTTP requests
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function Register({ onSuccess, loginSucess }) {
  // Added onSuccess as a prop
  const [openLogin, setOpenLogin] = useState(false);
  const [registerData, setRegisterData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [registerErrors, setRegisterErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  const validateRegisterForm = () => {
    let isValid = true;
    let errors = { username: "", email: "", password: "", confirmPassword: "" };

    if (!registerData.username) {
      errors.username = "Username is required.";
      isValid = false;
    }

    if (!registerData.email || !/\S+@\S+\.\S+/.test(registerData.email)) {
      errors.email = "Please enter a valid email.";
      isValid = false;
    }

    if (!registerData.password || registerData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    if (registerData.password !== registerData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
      isValid = false;
    }

    setRegisterErrors(errors);
    return isValid;
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!validateRegisterForm()) return;

    try {
      const response = await axios.post(
        "https://backend.lukzbet.com/api/register",
        {
          name: registerData.username,
          email: registerData.email,
          password: registerData.password,
        }
      );

      if (response.data.status) {
        toast.success("Registration successful!");
        setRegisterData({
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
        });

        // Call the onSuccess prop to close the dialog
        setTimeout(() => {
          onSuccess();
        }, 2000);
      }
    } catch (error) {
      const message = error.response?.data?.message || "Registration failed.";
      toast.error("Registration failed.");
    }
  };

  const handleLogin = () => {
    onSuccess();
    loginSucess();
  };

  return (
    <Box>
      <Toaster />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#342D56",
        }}
      >
        <Avatar
          sx={{ width: "20%", height: "10vh", borderRadius: "10px" }}
          src={logo}
        />
      </Box>
      <DialogContent>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            label="Username"
            name="username"
            type="text"
            value={registerData.username}
            onChange={handleRegisterChange}
            error={!!registerErrors.username}
            helperText={registerErrors.username}
            fullWidth
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={registerData.email}
            onChange={handleRegisterChange}
            error={!!registerErrors.email}
            helperText={registerErrors.email}
            fullWidth
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={registerData.password}
            onChange={handleRegisterChange}
            error={!!registerErrors.password}
            helperText={registerErrors.password}
            fullWidth
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={registerData.confirmPassword}
            onChange={handleRegisterChange}
            error={!!registerErrors.confirmPassword}
            helperText={registerErrors.confirmPassword}
            fullWidth
          />
        </Box>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Button
          onClick={handleRegisterSubmit}
          type="submit"
          sx={{ width: "100%", backgroundColor: "#C79836" }}
          variant="contained"
        >
          Register
        </Button>
        <Typography sx={{ color: "black" }}>
          Already have an account?{" "}
          <Link href="#" onClick={handleLogin}>
            Login
          </Link>
        </Typography>
      </Box>

      {/* Snackbar for alert messages */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
