import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import upload from "../images/upload.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import keta from "../images/Keta.jpg";
import keta1 from "../images/keta1.jpg";
import axios from "axios";
import Swal from "sweetalert2";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("05", 159, 6.0, 24, 4.0),
  createData("04", 237, 9.0, 37, 4.3),
  createData("03", 262, 16.0, 24, 6.0),
  createData("02", 305, 3.7, 67, 4.3),
  createData("01", 356, 16.0, 49, 3.9),
];

export default function Withdraw() {
  // const [formData, setFormData] = useState({
  //     account_holder_name: "",
  //     account_no: "",
  //     bank: "",
  //     branch: "",
  //     withdraw_amount: "",
  //   });

  const [acountHolderName, setAcountHolderName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");

  const formData = new FormData();
  formData.append("account_holder_name", acountHolderName);
  formData.append("account_no", accountNo);
  formData.append("bank", bank);
  formData.append("branch", branch);
  formData.append("withdraw_amount", withdrawAmount);

  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    // Fetch the current balance from the backend
    const fetchBalance = async () => {
      try {
        const response = await axios.get(
          "https://backend.lukzbet.com/api/current-balance",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`, // Include token if necessary for auth
            },
          }
        );
        setBalance(response.data.balance);
      } catch (err) {}
    };

    fetchBalance();
  }, []);

  //   const handleChange = (e) => {
  //     setFormData({
  //       ...formData,
  //       [e.target.name]: e.target.value,
  //     });
  //   };

  const [bankDetails, setBankDetails] = useState([]);

  useEffect(() => {
    // Fetch bank details
    axios
      .get("https://backend.lukzbet.com/api/show-bank-details")
      .then((response) => {
        setBankDetails(response.data[0]); // Assuming you want the first record
      })
      .catch((error) => {
        console.error("Error fetching bank details:", error);
      });
  }, []);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    setErrors(null);

    try {
      // Send POST request to the backend API
      const response = await axios.post(
        "https://backend.lukzbet.com/api/withdraw-profit",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`, // Assuming token is stored in localStorage
          },
        }
      );
      setMessage(response.data.message);
      //   setFormData({
      //     account_holder_name: "",
      //     account_no: "",
      //     bank: "",
      //     branch: "",
      //     withdraw_amount: "",
      //   });
    } catch (error) {
      // Handle validation and other errors
      if (error.response) {
        setErrors(error.response.data.errors || error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const popup = () => {
    Swal.fire({
      title: "Are you sure?",

      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "Deposit",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Deposited!",
          "Your Amount file has been deposited.",
          "success"
        );
        handleSubmit(); // This now works without needing an event
      }
    });
  };

  const [depositDetails, setDepositDetails] = useState();
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    const fetchDepositDetails = async () => {
      try {
        const response = await axios.get(
          "https://backend.lukzbet.com/api/user-withdraw-details",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token if needed
            },
          }
        );
        setDepositDetails(response.data); // Assuming the API returns an array
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching deposit details:", error);
        setDepositDetails([]); // Set to empty array if there's an error
      }
    };

    fetchDepositDetails();
  }, [token]); // Run when the component mounts or when the token changes

  return (
    <Box
      sx={{
        width: "100%",
        height: {
          xs: "140vh",
          sm: "140vh",
          md: "140vh",
          lg: "100vh",
          xl: "100vh",
        },
        backgroundColor: "#0C314D",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "95%",
          height: "95vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: { md: "#0C314D", lg: "#103958", xl: "#103958" },
          borderRadius: "10px",
          flexDirection: { xs: "column", md: "column", lg: "row", xl: "row" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "90%",
              height: { xs: "60%", sm: "60%", md: "60%", lg: "60%", xl: "50%" },
              backgroundColor: "#0C314D",
              marginLeft: "2%",
              marginTop: "2%",
              borderRadius: "10px",
              padding: "2%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                },
              }}
              variant="h5"
            >
              Enter Your Withdraw Details
            </Typography>
            <Typography
              sx={{
                textAlign: "start",
                marginTop: "20px",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                },
                color: "white",
              }}
              variant="h5"
            >
              Current Account Balance :{" "}
              <span style={{ color: "green" }}>{balance}</span>
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "20px",
                fontSize: "18px",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: {
                    xs: "0px",
                    sm: "0px",
                    md: "0px",
                    lg: "0px",
                    xl: "20px",
                  },
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                    xl: "20px",
                  },
                  color: "white",
                }}
                variant="h5"
              >
                min Amount: {bankDetails.Min_Amount}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: {
                    xs: "0px",
                    sm: "0px",
                    md: "0px",
                    lg: "0px",
                    xl: "20px",
                  },
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                    xl: "20px",
                  },
                  color: "white",
                }}
                variant="h5"
              >
                max Ammount: {bankDetails.Max_Amount}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "50%" }}
              >
                <TextField
                  size="small"
                  variant="standard"
                  type="number"
                  placeholder="Enter amount"
                  value={withdrawAmount}
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  InputProps={{
                    style: {
                      color: "white", // Set the text color to white
                    },
                  }}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "20px",
                      xl: "30px",
                    },
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white", // Set the underline to white
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white", // Set the underline after focus to white
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // Set the label color to white (if applicable)
                    },
                  }}
                />

                <TextField
                  size="small"
                  variant="standard"
                  placeholder="Enter Account Number"
                  value={accountNo}
                  onChange={(e) => setAccountNo(e.target.value)}
                  InputProps={{
                    style: {
                      color: "white", // Set the text color to white
                    },
                  }}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "20px",
                      xl: "30px",
                    },
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white", // Set the underline to white
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white", // Set the underline after focus to white
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // Set the label color to white (if applicable)
                    },
                  }}
                />

                <TextField
                  size="small"
                  variant="standard"
                  placeholder="Bank Name"
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                  InputProps={{
                    style: {
                      color: "white", // Set the text color to white
                    },
                  }}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "20px",
                      xl: "30px",
                    },
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white", // Set the underline to white
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white", // Set the underline after focus to white
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // Set the label color to white (if applicable)
                    },
                  }}
                />
                <Button
                  type="submit"
                  onClick={popup}
                  sx={{
                    marginTop: "20px",
                    width: "90%",
                    backgroundColor: "#3A6D8C",
                    color: "white",
                  }}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Withdraw"}
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <TextField
                  size="small"
                  variant="standard"
                  placeholder="Bank Holder Name"
                  value={acountHolderName}
                  onChange={(e) => setAcountHolderName(e.target.value)}
                  InputProps={{
                    style: {
                      color: "white", // Set the text color to white
                    },
                  }}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "20px",
                      xl: "30px",
                    },
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white", // Set the underline to white
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white", // Set the underline after focus to white
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // Set the label color to white (if applicable)
                    },
                  }}
                />
                <TextField
                  size="small"
                  variant="standard"
                  placeholder="Branch"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  InputProps={{
                    style: {
                      color: "white", // Set the text color to white
                    },
                  }}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "20px",
                      xl: "30px",
                    },
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white", // Set the underline to white
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white", // Set the underline after focus to white
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // Set the label color to white (if applicable)
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ width: "90%", height: "40vh",  marginLeft:"2%", marginTop:"2%", borderRadius: "10px", padding:"2%" }}>
                        <Typography sx={{ textAlign: "center", fontWeight: "bold", color: "white" }} variant="h5">Most Popular Games</Typography>
                        <Box sx={{display:"flex", flexDirection:"row", marginTop:"40px"}}>
                            <Box sx={{marginTop:"20px", cursor:"pointer", overflow:"hidden",  borderRadius:"10px", color:"black", fontWeight:"bold", width:"50%", height:"300px",  backgroundColor:"white", textAlign:"center", borderRadius:"5px" }}>
                                <Avatar sx={{width:"100%", height:"70%","&:hover": {transform: "scale(1.1)"}, transition: "transform 0.3s ease-in-out", borderRadius:"0px", borderRadius:"5px 5px 0px 0px"}} src={keta} />
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h6">Buruwo</Typography>
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h5">All</Typography>
                            </Box>
                            <Box sx={{marginTop:"20px", marginLeft:"20px", overflow:"hidden", borderRadius:"10px", color:"black", fontWeight:"bold", width:"50%", height:"300px",  backgroundColor:"white", textAlign:"center", borderRadius:"5px" }}>
                                <Avatar sx={{width:"100%", height:"70%", "&:hover": {transform: "scale(1.1)"}, transition: "transform 0.3s ease-in-out", borderRadius:"0px", borderRadius:"5px 5px 0px 0px"}} src={keta1} />
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h6">Keta</Typography>
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h5">All</Typography>
                            </Box>  
                        </Box>
                    </Box> */}
        </Box>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            sx={{
              width: "90%",
              height: "87vh",
              backgroundColor: "#0C314D",
              marginLeft: "2%",
              marginTop: { xs: "40%", sm: "2%", md: "2%", lg: "2%", xl: "2%" },
              marginRight: "2%",
              marginBottom: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: { md: "#0C314D", lg: "#103958", xl: "#103958" },
              borderRadius: "10px",
              padding: "2%",
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontWeight: "bold", color: "white" }}
              variant="h5"
            >
              Withdraw Transaction Details
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "20px",
                backgroundColor: "#103958",
                height: "93%",
                overflowY: "auto",
              }}
            >
              <Table sx={{ maxHeight: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "white" }} align="center">
                      Transaction Number
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="right">
                      Date
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="right">
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {depositDetails && depositDetails.length > 0 ? (
                    depositDetails.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "20px",
                            },
                          }}
                          component="th"
                          scope="row"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {new Date(row.created_at).toISOString().split("T")[0]}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {row.withdraw_amount}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {row.status}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "12px",
                            lg: "10px",
                            xl: "20px",
                          },
                        }}
                      >
                        No deposit details available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
