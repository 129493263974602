import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios"; // Import Axios for API calls
import { useNavigate } from "react-router-dom";
import "./admin.css";
import BgAdmin from "../images/admin.jpg";
import User from "./user";
import Transaction from "./transaction";
import Notify from "./Notify";

export default function Admin() {
  const [activeButton, setActiveButton] = useState("user");
  const [open, setOpen] = useState(false); // For the popup
  const [bankDetails, setBankDetails] = useState({
    account_number: "",
    branch_name: "",
    bank_name: "",
    bank_holder_name: "",
    max_withdrawal_limit: "",
    min_withdrawal_limit: "",
  });

  const [prevData, setPrevData] = useState(null); // Initially set to null to handle loading state

  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [error, setError] = useState(null);

  // Handle the input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankDetails, [name]: value });
  };

  useEffect(() => {
    // Fetch the current balance from the backend
    const fetchBalance = async () => {
      try {
        const response = await axios.get(
          "https://backend.lukzbet.com/api/current-balance",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`, // Include token if necessary for auth
            },
          }
        );
        setRole(response.data.role);
        console.log(response.data.role);
      } catch (err) {
        setError(err.response ? err.response.data.error : "An error occurred");
      }
    };

    fetchBalance();
  }, []);

  // Handle form submission to update bank details
  const handleSubmit = async () => {
    const dataToSubmit = {
      ...bankDetails,
      max_amount: bankDetails.max_withdrawal_limit,
      min_amount: bankDetails.min_withdrawal_limit,
    };
    try {
      const response = await axios.post(
        "https://backend.lukzbet.com/api/super-admin/update-bank-details", // Change to your backend API URL
        dataToSubmit, // Pass bank details from the form
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`, // Pass the token for authentication
          },
        }
      );
      if (response.data.message) {
        alert("Bank details updated successfully");
        setOpen(false); // Close the dialog
      }
    } catch (error) {
      alert("Error updating bank details");
      console.error(error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    navigate(`/admin/${buttonId}`);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  useEffect(() => {
    // Fetch bank details from the API
    axios
      .get("https://backend.lukzbet.com/api/show-bank-details", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token if required
        },
      })
      .then((response) => {
        const fetchedData = response.data[0]; // Assuming you're fetching a single object
        setPrevData(fetchedData); // Store fetched data
        setBankDetails({
          account_number: fetchedData.Account_number || "",
          branch_name: fetchedData.Branch_name || "",
          bank_name: fetchedData.Bank_name || "",
          bank_holder_name: fetchedData.Bank_Holder_Name || "",
          max_withdrawal_limit: fetchedData.Max_Amount || "",
          min_withdrawal_limit: fetchedData.Min_Amount || "",
        }); // Pre-fill the form with fetched data
      })
      .catch((error) => {
        console.error("Error fetching bank details:", error);
      });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${BgAdmin})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundColor: "#347928",
      }}
    >
      <Box
        sx={{
          width: "70%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "50px",
          marginTop: "50px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "white",
            fontWeight: "bold",
            width: { lg: "70%", xl: "80%" },
            fontSize: { lg: "30px", xl: "50px" },
          }}
        >
          Admin Dashboard
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "rgb(2, 2, 104)", color: "white" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
        {role === "2" && (
          <Button
            variant="contained"
            sx={{ backgroundColor: "rgb(2, 2, 104)", color: "white" }}
            onClick={() => setOpen(true)}
          >
            Edit Bank Details
          </Button>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "white",
          padding: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: "60px",
          marginBottom: "100px",
        }}
      >
        <Box sx={{ width: "70%", display: "flex", justifyContent: "center" }}>
          <button
            className={
              activeButton === "user"
                ? "p-setting-active"
                : "p-setting-inactive"
            }
            onClick={() => handleButtonClick("user")}
          >
            User
          </button>
          <button
            className={
              activeButton === "transaction"
                ? "p-setting-active"
                : "p-setting-inactive"
            }
            onClick={() => handleButtonClick("transaction")}
          >
            Withdrawals
          </button>
          <button
            className={
              activeButton === "notify"
                ? "p-setting-active"
                : "p-setting-inactive"
            }
            onClick={() => handleButtonClick("notify")}
          >
            Deposits
          </button>
        </Box>
      </Box>

      {activeButton === "user" && <User />}
      {activeButton === "transaction" && <Transaction />}
      {activeButton === "notify" && <Notify />}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Bank Details</DialogTitle>
        <DialogContent>
          {prevData ? (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Account Number"
                type="text"
                fullWidth
                name="account_number"
                value={bankDetails.account_number}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                label="Branch Name"
                type="text"
                fullWidth
                name="branch_name"
                value={bankDetails.branch_name}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                label="Bank Name"
                type="text"
                fullWidth
                name="bank_name"
                value={bankDetails.bank_name}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                label="Bank Holder Name"
                type="text"
                fullWidth
                name="bank_holder_name"
                value={bankDetails.bank_holder_name}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                label="Max Withdrawal Limit"
                type="text"
                fullWidth
                name="max_withdrawal_limit"
                value={bankDetails.max_withdrawal_limit}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                label="Min Withdrawal Limit"
                type="text"
                fullWidth
                name="min_withdrawal_limit"
                value={bankDetails.min_withdrawal_limit}
                onChange={handleInputChange}
              />
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
