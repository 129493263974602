import {
  Avatar,
  Box,
  Button,
  DialogContent,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios"; // For making HTTP requests
import { useNavigate } from "react-router-dom"; // For handling navigation
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function SignIn({ onSuccess, registerSucess }) {
  const [openRegister, setOpenRegister] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateLoginForm = () => {
    let isValid = true;
    let errors = { email: "", password: "" };

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email.";
      isValid = false;
    }

    if (!formData.password || formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!validateLoginForm()) return;

    try {
      const response = await axios.post(
        "https://backend.lukzbet.com/api/login",
        {
          email: formData.email,
          password: formData.password,
        }
      );

      if (response.data.status) {
        // Store token and redirect based on role
        const token = response.data.token;
        const redirectUrl = response.data.redirect_url;

        // Store token in localStorage
        localStorage.setItem("auth_token", token);

        // Show success alert and redirect
        toast.success("Logged in successfully!");

        // Navigate to the correct route based on the user's role
        setTimeout(() => {
          navigate(redirectUrl);
          onSuccess();
        }, 2000);
      }
    } catch (error) {
      const message = error.response?.data?.message || "Login failed.";
      toast.error("Login failed.");
    }
  };

  const handleRegiser = () => {
    // onSuccess();
    registerSucess();
  };

  return (
    <Box>
      <Toaster />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#342D56",
        }}
      >
        <Avatar
          sx={{ width: "20%", height: "10vh", borderRadius: "10px" }}
          src={logo}
        />
      </Box>
      <DialogContent>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            fullWidth
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            error={!!formErrors.password}
            helperText={formErrors.password}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Link href="">Forgot your password?</Link>
        </Box>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Button
          onClick={handleLoginSubmit}
          sx={{ width: "100%", backgroundColor: "#C79836" }}
          variant="contained"
          color="primary"
        >
          Login
        </Button>
        <Typography sx={{ color: "black" }} variant="p">
          Don't have an account?{" "}
          <Link href="#" onClick={handleRegiser}>
            Register
          </Link>
        </Typography>
      </Box>

      {/* Snackbar for alert messages */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
