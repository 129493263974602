import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
// import axios from 'axios';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// import BgImage from '../../../public/assets/Bg-image.jpg';
// import Footer from '../../components/Footer/footer';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate, useParams } from "react-router-dom";
import "./admin.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Swal from "sweetalert2";

export default function Deposits() {
  const [selectedOption, setSelectedOption] = useState("deposits");
  const [deposit, setDeposit] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [approve, setApprove] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedDepositId, setSelectedDepositId] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("auth_token");

  const [successMessage, setSuccessMessage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const response = await axios.get(
          "https://backend.lukzbet.com/api/admin/deposits",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setDeposits(response.data.data);
      } catch (err) {
        setError("Failed to fetch deposit details.");
      } finally {
        setLoading(false);
      }
    };

    fetchDeposits();
  }, [token]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleApprove = async (depositId) => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await axios.put(
        `https://backend.lukzbet.com/api/admin/deposit/${depositId}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        setSuccessMessage(`Deposit ${depositId} approved successfully.`);
        // Optionally, reload the deposit data after approval or update the UI
      }
    } catch (err) {
      setError("Failed to approve deposit. Please try again.");
    } finally {
      setLoading(false);
    }
    closeConfirmDialog();
  };

  const handleReject = async (depositId) => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await axios.put(
        `https://backend.lukzbet.com/api/admin/deposit/${depositId}/reject`, // Change the endpoint to reject
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        setSuccessMessage(`Deposit ${depositId} rejected successfully.`);
        // Optionally, reload the deposit data after rejection or update the UI
      }
    } catch (err) {
      setError("Failed to reject deposit. Please try again.");
    } finally {
      setLoading(false);
    }
    closeConfirmDialog();

    window.location.reload(); // Reload the page to refresh the data
  };

  //   useEffect(() => {
  //     const fetchDeposits = async () => {
  //         try {
  //             const response = await axios.get('https://backend.lukzbet.com/api/admin/deposits', {
  //                 headers: {
  //                     'Authorization': `Bearer ${token}`,
  //                 },
  //             });
  //             setDeposit(response.data.data);
  //         } catch (err) {
  //             setError(err.response ? err.response.data.error : 'Something went wrong');
  //         }
  //     };

  //     fetchDeposits();
  // }, []);

  // const handleApprove = async (id) => {
  //   try {
  //     await axios.put(`https://backend.lukzbet.com/api/admin/deposit/${id}/approve`, {}, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });
  //     setDeposit(deposit.map(d => d.id === id ? { ...d, status: 'Approved' } : d));
  //   } catch (err) {
  //     setError(err.response ? err.response.data.error : 'Something went wrong');
  //   }
  // };

  // const handleReject = async (id) => {
  //   try {
  //     await axios.put(`https://backend.lukzbet.com/api/admin/deposit/${id}/reject`, {}, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });
  //     setDeposit(deposit.map(d => d.id === id ? { ...d, status: 'Rejected' } : d));
  //   } catch (err) {
  //     setError(err.response ? err.response.data.error : 'Something went wrong');
  //   }
  // };

  if (error) {
    return <div>{error}</div>;
  }

  const openConfirmDialog = (id, type) => {
    setSelectedDepositId(id);
    setActionType(type);

    if (type === "approve") {
      Swal.fire({
        title: "Are you sure you want to approve?",
        text: "You will not be able to recover this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "green",
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Delay the success message and approval logic by 2 seconds

          Swal.fire(
            "Approved!",
            "Your imaginary file has been approved.",
            "success"
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          handleApprove(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    }

    if (type === "reject") {
      Swal.fire({
        title: "Are you sure you want to reject?",
        text: "You will not be able to recover this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "red",
        confirmButtonText: "Yes, reject it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Delay the success message and approval logic by 2 seconds

          Swal.fire(
            "Rejected!",
            "Your imaginary file has been rejected.",
            "success"
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          handleReject(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    }
  };

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
    setSelectedDepositId(null);
    setActionType(null);
  };

  // const handleConfirm = async () => {
  //   if (actionType === 'approve') {
  //     await handleApprove(selectedDepositId);
  //   } else if (actionType === 'reject') {
  //     await handleReject(selectedDepositId);
  //   }
  //   setConfirmOpen(false);
  // };

  return (
    <Box>
      <Box
        sx={{
          width: "800px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "50px",
          height: "100%",
        }}
      >
        <Box sx={{ width: "100%", marginBottom: "20px", marginTop: "-100px" }}>
          <Typography variant="h5" sx={{ color: "black", fontWeight: "bold" }}>
            All Deposts
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ width: "140%", height: 500, overflow: "auto" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  User Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Transaction ID
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Amount
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Image
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            {/* {packages.map((pkg) => ( */}

            {deposits.map((deposit) => (
              <TableRow key={deposit.id}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "20%" }}
                  align="center"
                >
                  {deposit.user_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "20%" }}
                  align="center"
                >
                  {deposit.transaction_id}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  {deposit.deposit_amount}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  <img
                    src={`https://backend.lukzbet.com/uploads/deposits/${deposit.split_image}`}
                    style={{
                      width: "50px",
                      objectFit: "cover",
                      height: "50px",
                    }}
                    onClick={() =>
                      handleOpen(
                        `https://backend.lukzbet.com/uploads/deposits/${deposit.split_image}`
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  {deposit.status === "Approved" ? (
                    <Button
                      disabled
                      sx={{
                        bgcolor: "#B4E380",
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      Approved
                    </Button>
                  ) : deposit.status === "Rejected" ? (
                    <Button
                      disabled
                      sx={{
                        bgcolor: "#EE4E4E",
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      Rejected
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() => openConfirmDialog(deposit.id, "approve")}
                        sx={{
                          ":hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                          alignItems: "center",
                          marginTop: "10px",
                          height: "30px",
                          backgroundColor: "green",
                          borderRadius: "5px",
                          fontSize: "10px",
                          color: "white",
                          marginRight: "10px",
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() => openConfirmDialog(deposit.id, "reject")}
                        sx={{
                          ":hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                          height: "30px",
                          marginTop: "10px",
                          backgroundColor: "red",
                          borderRadius: "5px",
                          fontSize: "10px",
                          color: "white",
                          marginRight: "10px",
                        }}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              border: "none",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Deposit Slip
            </Typography>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Deposit"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleClose}
                sx={{
                  mt: 2,
                  backgroundColor: "red",
                  color: "white",
                  ":hover": { backgroundColor: "darkred" },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>

        <Dialog
          open={confirmOpen}
          onClose={closeConfirmDialog}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title">
            {"Confirm Action"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              Are you sure you want to {actionType} this deposit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (actionType === "approve") {
                  handleApprove(selectedDepositId); // Call approve if action is 'approve'
                } else if (actionType === "reject") {
                  handleReject(selectedDepositId); // Call reject if action is 'reject'
                }
              }}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
