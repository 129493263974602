import { Avatar, Box, Button, Dialog, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Dashboard from "../Components/Dashboard";
import Register from "../Components/Register";
import SignIn from "../Components/SignIn";
import Deposit from "../Components/deposit"; // Assuming you have a Deposit component
import Withdraw from "../Components/Withdraw";
import Account from "../Components/Account";
import logo from "../images/logo.png";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Pusher from "pusher-js";

export default function Home() {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentView, setCurrentView] = useState("dashboard");
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState(null);
  const [betData, setBetData] = useState([]);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("27257c565dad47b9cc64", {
      cluster: "ap2",
      forceTLS: true,
    });

    // Subscribe to the 'bets' channel
    const channel = pusher.subscribe("bets");

    // Bind to the 'BetPlace' event
    channel.bind("BetPlace", (data) => {
      // Handle the incoming bet data
      setBetData((prevData) => [...prevData, data]); // Update the state with new bet data
    });

    // Cleanup on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Check if token exists in localStorage on component mount
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    // Fetch the current balance from the backend
    const fetchBalance = async () => {
      try {
        const response = await axios.get(
          "https://backend.lukzbet.com/api/current-balance",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`, // Include token if necessary for auth
            },
          }
        );
        setBalance(response.data.balance);
      } catch (err) {
        setError(err.response ? err.response.data.error : "An error occurred");
      }
    };

    fetchBalance();
  }, []);

  const handleClickOpenLogin = () => {
    handleCloseRegister();
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleClickOpenRegister = () => {
    handleCloseLogin();
    setOpenRegister(true);
    setOpenLogin(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleLoginSuccess = (token) => {
    // Store token in localStorage and update auth state
    localStorage.setItem("authToken", token);
    setIsAuthenticated(true);
    handleCloseLogin();
  };

  const handleLogout = () => {
    // Remove token from localStorage and reset auth state
    localStorage.removeItem("authToken");
    setIsAuthenticated(false);
    toast.success("Logged out successfully");
    setTimeout(() => {
      setCurrentView("dashboard");
    }, 1500);
    // Reset view to dashboard after logout
  };

  // Conditionally render the right-hand component
  const renderComponent = () => {
    if (currentView === "dashboard") {
      return <Dashboard />;
    } else if (currentView === "deposit") {
      return <Deposit />;
    } else if (currentView === "withdraw") {
      return <Withdraw />;
    } else if (currentView === "account") {
      return <Account />;
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Toaster />
      <Box
        sx={{
          width: "300px",
          height: "100vh",
          backgroundColor: "#103958",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          },
          flexDirection: "column",
        }}
      >
        {isAuthenticated ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Avatar
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
                width: "90%",
                height: "30vh",
                borderRadius: "10px",
              }}
              src={logo}
            />

            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "10px",
                width: "80%",
                height: "10vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "80%",
                  height: "5vh",
                  borderRadius: "10px",
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                }}
                variant="h5"
              >
                Wallet{" "}
              </Typography>
              <Typography
                sx={{
                  width: "80%",
                  height: "5vh",
                  borderRadius: "10px",
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                }}
                variant="h6"
              >
                LKR {balance}.00
              </Typography>
            </Box>
            <Button
              onClick={() => setCurrentView("dashboard")} // Show Dashboard
              sx={{
                marginTop: "20px",
                width: "80%",
                height: "5vh",
                borderRadius: "10px",
                color: "white",
              }}
              variant="contained"
            >
              Home
            </Button>
            <Button
              onClick={() => setCurrentView("deposit")} // Show Deposit
              sx={{
                marginTop: "20px",
                width: "80%",
                height: "5vh",
                borderRadius: "10px",
                color: "white",
              }}
              variant="contained"
            >
              Deposit
            </Button>

            <Button
              onClick={() => setCurrentView("withdraw")} // Show Deposit
              sx={{
                marginTop: "20px",
                width: "80%",
                height: "5vh",
                borderRadius: "10px",
                color: "white",
              }}
              variant="contained"
            >
              Withdraw
            </Button>
            <Button
              onClick={handleLogout}
              sx={{
                marginTop: "20px",
                width: "80%",
                height: "5vh",
                borderRadius: "10px",
                color: "white",
              }}
              variant="contained"
            >
              Logout
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Avatar
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
                width: "90%",
                height: "30vh",
                borderRadius: "10px",
              }}
              src={logo}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                height: "60%",
              }}
            >
              <Button
                onClick={handleClickOpenLogin}
                sx={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "80%",
                  height: "5vh",
                  borderRadius: "10px",
                  color: "white",
                }}
                variant="contained"
              >
                Login
              </Button>
              <Button
                onClick={handleClickOpenRegister}
                sx={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "80%",
                  height: "5vh",
                  borderRadius: "10px",
                  color: "white",
                }}
                variant="contained"
              >
                Register
              </Button>
              <Box
                sx={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "80%",
                  height: "8vh",
                  borderRadius: "10px",
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 -960 960 960"
                    width="48px"
                    fill="#e8eaed"
                  >
                    <path d="m604-389 40-145-124-85-40 145 124 85ZM195-160l-66-27q-32.17-14.03-43.58-48.01Q74-269 92-302l103-245v387Zm142 58q-33.83 0-57.91-24Q255-150 255-184v-313l137 368q3 8 6.5 14t9.5 13h-71Zm183-25q-23 8-45.93-2.2Q451.14-139.41 443-162L251-684q-8-23 2.44-45.88Q263.88-752.75 287-761l319-115q23-8 46.5 2t31.5 33l193 521q8 23-2.2 45.87Q864.59-251.25 842-243L520-127Zm-20-57 318-116-191-519-318 115 191 520Zm64-318Z" />
                  </svg>
                  <Typography
                    sx={{ color: "white", fontSize: "12px", marginTop: "10px" }}
                    variant="p"
                  >
                    Slots
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    "&:hover": { fill: "red" },
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 -960 960 960"
                    width="48px"
                    fill="#e8eaed"
                  >
                    <path d="M397-524q16.58 0 27.79-11.21T436-563q0-16.58-11.21-27.79T397-602q-16.58 0-27.79 11.21T358-563q0 16.58 11.21 27.79T397-524Zm0 167q16.58 0 27.79-11.21T436-396q0-16.58-11.21-27.79T397-435q-16.58 0-27.79 11.21T358-396q0 16.58 11.21 27.79T397-357ZM268-539q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm128.57 296q9.43 0 16.93-7.2T421-267q0-9.6-7.29-16.8-7.28-7.2-17-7.2-9.71 0-16.71 7.2t-7 16.8q0 9.6 7.07 16.8 7.07 7.2 16.5 7.2ZM268-372q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm129-296q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm167 144q16.58 0 27.79-11.21T603-563q0-16.58-11.21-27.79T564-602q-16.58 0-27.79 11.21T525-563q0 16.58 11.21 27.79T564-524Zm0-144q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm129 296q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm0-167q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm.5-60q140.5 0 240-99.5T820-480q0-141-99.5-240.5t-240-99.5Q340-820 240-720.5T140-480q0 141 100 240.5T480.5-140ZM564-243q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm0-114q16.58 0 27.79-11.21T603-396q0-16.58-11.21-27.79T564-435q-16.58 0-27.79 11.21T525-396q0 16.58 11.21 27.79T564-357Zm-84-123Z" />
                  </svg>
                  <Typography
                    sx={{ color: "white", fontSize: "12px", marginTop: "10px" }}
                    variant="p"
                  >
                    Slots
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
          backgroundColor: "#0C314D",
        }}
      >
        <Button
          onClick={handleToggle}
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "flex",
              lg: "none",
              xl: "none",
              zIndex: "1001",
            },
          }}
        >
          {isOpen ? "Close" : "Menu"}
        </Button>
        {isOpen && (
          <Box
            sx={{
              width: "300px",
              height: "100vh",
              backgroundColor: "#103958",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "1000",
            }}
          >
            {isAuthenticated ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Avatar
                  sx={{
                    marginTop: "20px",
                    marginLeft: "20px",
                    width: "90%",
                    height: "30vh",
                    borderRadius: "10px",
                  }}
                  src={logo}
                />

                <Box
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "10px",
                    width: "80%",
                    height: "10vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      marginTop: "10px",
                      width: "80%",
                      height: "5vh",
                      borderRadius: "10px",
                      color: "white",
                      textAlign: "center",
                    }}
                    variant="h5"
                  >
                    Wallet{" "}
                  </Typography>
                  <Typography
                    sx={{
                      width: "80%",
                      height: "5vh",
                      borderRadius: "10px",
                      color: "white",
                      textAlign: "center",
                    }}
                    variant="h6"
                  >
                    LKR 10,000{" "}
                  </Typography>
                </Box>
                <Button
                  onClick={() => setCurrentView("dashboard")} // Show Dashboard
                  sx={{
                    marginTop: "20px",
                    width: "80%",
                    height: "5vh",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Home
                </Button>
                <Button
                  onClick={() => setCurrentView("deposit")} // Show Deposit
                  sx={{
                    marginTop: "20px",
                    width: "80%",
                    height: "5vh",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Deposit
                </Button>

                <Button
                  onClick={() => setCurrentView("withdraw")} // Show Deposit
                  sx={{
                    marginTop: "20px",
                    width: "80%",
                    height: "5vh",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Withdraw
                </Button>
                <Button
                  onClick={handleLogout}
                  sx={{
                    marginTop: "20px",
                    width: "80%",
                    height: "5vh",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Logout
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Avatar
                  sx={{
                    marginTop: "20px",
                    marginLeft: "20px",
                    width: "90%",
                    height: "30vh",
                    borderRadius: "10px",
                  }}
                  src={logo}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "80%",
                    height: "60%",
                  }}
                >
                  <Button
                    onClick={handleClickOpenLogin}
                    sx={{
                      marginTop: "20px",
                      marginLeft: "20px",
                      width: "80%",
                      height: "5vh",
                      borderRadius: "10px",
                      color: "white",
                    }}
                    variant="contained"
                  >
                    Login
                  </Button>
                  <Button
                    onClick={handleClickOpenRegister}
                    sx={{
                      marginTop: "20px",
                      marginLeft: "20px",
                      width: "80%",
                      height: "5vh",
                      borderRadius: "10px",
                      color: "white",
                    }}
                    variant="contained"
                  >
                    Register
                  </Button>
                  <Box
                    sx={{
                      marginTop: "20px",
                      marginLeft: "20px",
                      width: "80%",
                      height: "8vh",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      width: "80%",
                      justifyContent: "space-around",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48px"
                        viewBox="0 -960 960 960"
                        width="48px"
                        fill="#e8eaed"
                      >
                        <path d="m604-389 40-145-124-85-40 145 124 85ZM195-160l-66-27q-32.17-14.03-43.58-48.01Q74-269 92-302l103-245v387Zm142 58q-33.83 0-57.91-24Q255-150 255-184v-313l137 368q3 8 6.5 14t9.5 13h-71Zm183-25q-23 8-45.93-2.2Q451.14-139.41 443-162L251-684q-8-23 2.44-45.88Q263.88-752.75 287-761l319-115q23-8 46.5 2t31.5 33l193 521q8 23-2.2 45.87Q864.59-251.25 842-243L520-127Zm-20-57 318-116-191-519-318 115 191 520Zm64-318Z" />
                      </svg>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          marginTop: "10px",
                        }}
                        variant="p"
                      >
                        Slots
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        "&:hover": { fill: "red" },
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48px"
                        viewBox="0 -960 960 960"
                        width="48px"
                        fill="#e8eaed"
                      >
                        <path d="M397-524q16.58 0 27.79-11.21T436-563q0-16.58-11.21-27.79T397-602q-16.58 0-27.79 11.21T358-563q0 16.58 11.21 27.79T397-524Zm0 167q16.58 0 27.79-11.21T436-396q0-16.58-11.21-27.79T397-435q-16.58 0-27.79 11.21T358-396q0 16.58 11.21 27.79T397-357ZM268-539q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm128.57 296q9.43 0 16.93-7.2T421-267q0-9.6-7.29-16.8-7.28-7.2-17-7.2-9.71 0-16.71 7.2t-7 16.8q0 9.6 7.07 16.8 7.07 7.2 16.5 7.2ZM268-372q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm129-296q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm167 144q16.58 0 27.79-11.21T603-563q0-16.58-11.21-27.79T564-602q-16.58 0-27.79 11.21T525-563q0 16.58 11.21 27.79T564-524Zm0-144q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm129 296q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm0-167q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm.5-60q140.5 0 240-99.5T820-480q0-141-99.5-240.5t-240-99.5Q340-820 240-720.5T140-480q0 141 100 240.5T480.5-140ZM564-243q9.6 0 16.8-7.2 7.2-7.2 7.2-16.8 0-9.6-7.2-16.8-7.2-7.2-16.8-7.2-9.6 0-16.8 7.2-7.2 7.2-7.2 16.8 0 9.6 7.2 16.8 7.2 7.2 16.8 7.2Zm0-114q16.58 0 27.79-11.21T603-396q0-16.58-11.21-27.79T564-435q-16.58 0-27.79 11.21T525-396q0 16.58 11.21 27.79T564-357Zm-84-123Z" />
                      </svg>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          marginTop: "10px",
                        }}
                        variant="p"
                      >
                        Slots
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
        {renderComponent()}
      </Box>

      {/* Login Dialog */}
      <Dialog open={openLogin} onClose={handleCloseLogin} fullWidth>
        <SignIn
          onSuccess={handleLoginSuccess}
          registerSucess={handleClickOpenRegister}
        />
      </Dialog>

      {/* Register Dialog */}
      <Dialog
        open={openRegister}
        onClose={handleCloseRegister}
        maxWidth="sm"
        fullWidth
      >
        <Register
          onSuccess={handleCloseRegister}
          loginSucess={handleClickOpenLogin}
        />
      </Dialog>
    </Box>
  );
}
